import React from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {Breadcrumb, Layout, Seo, Share} from '@app/components';

import sbb from '@app/assets/images/sbb-about.png';
import merihTombul from '@app/assets/images/merih-tombul.png';

const Tag: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.Tag',
  };
  const {t, language} = useLocale();

  return (
    <Layout>
      <Breadcrumb title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className="container mx-auto px-4 pt-20 pb-5 lg:px-0 xl:max-w-container-xl text-lg">
        {language == 'tr' && (
          <>
            <p className="my-2.5">
              <img className="mb-10" src={sbb} width={500} />
              <b className="font-bold mb-5 block">
                Dr.Serdar Bora Bayraktaroğlu
              </b>
              <p className="">
                Serdar Bora Bayraktaroğlu 2005 yılında Cerrahpaşa Tıp Fakültesi
                İngilizce Tıp Programı'ndan mezun oldu. Plastik cerrahi
                eğitimini 2005-2011 yılları arasında Şişli Etfal Hastanesi
                Plastik Rekonstruktif ve Estetik Cerrahi Kliniği’nde tamamladı.
                Aynı klinikte ‘Mikrocerrahi ve El Cerrahisi’ eğitimini bitirdi.
                Dr. Bayraktaroğlu, 2009 yılı ocak ayında, Amerika’da
                California-LA’ da The Facial Paralysis Institute’de altı aylık
                ‘Yüz Estetiği’ stajını başarıyla tamamladı.
              </p>
              <p className="mt-5">
                Mikrovasküler cerrahi alanında tezini tamamlayan Dr.
                Bayraktaroğlu, 2011’den bu yana Plastik Rekonstruktif ve Estetik
                Cerrahi Uzmanı olarak çalışıyor. Akademik kariyerini kök hücre
                doktorasıyla zenginleştiren Dr. Bayraktaroğlu, aynı zamanda ilgi
                alanı olan felsefe bölümünde de lisans öğrencisi.
              </p>
              <p className="mt-5">
                Türk Plastik ve Rekonstruktif Cerrahi Derneği ve ISAPS (
                international society of aesthetic plastic surgeons derneği)
                üyesi olan Dr. Bayraktaroğlu, çok sayıda ulusal ve uluslararası
                organizasyonda estetik plastik cerrahiye dair sunumlar yapıyor;
                uluslararası platformlarda antiaging ve medikal estetik
                konularında dünyadaki gelişmeleri yakından takip ediyor.
              </p>
              <p className="mt-5">
                Tıp alanındaki profesyonel çalışmalarının yanında deneyimlerini
                ve yıllardır süren araştırmalarını paylaştığı “Filtresiz Bir
                Dünya Mümkün” isimli kitabı; ‘Bir Plastik Cerrahın Aykırı
                Karalama Defteri’ notuyla 2024 Ocak’ta Doğan Kitap’tan çıktı.
                Estetik plastik cerrahiye dair çarpıcı konuları gündemine aldığı
                ikinci kitabı ise yazım aşamasında.
              </p>
            </p>
            <p className="my-2.5 mt-20 grid lg:grid-cols-12 gap-8 mb-8">
              <div className="lg:col-span-6">
                <img src={merihTombul} width={500} />
              </div>

              <div className="lg:col-span-6">
                <b className="font-bold mb-5 block mt-10">Dr. Merih Tombul</b>
                <p className="">
                  2008 yılında Trakya Üniversitesi Tıp Fakültesi’nden mezun
                  olduktan sonra 2009-2013 yıllarında Şişli Etfal Eğitim ve
                  Araştırma ve Florence Nightingale Hastanesinde uzmanlık
                  görevinin ardından Anesteziyoloji ve Yoğun bakım deneyimlerini
                  akupunktur , mezoterapi, ozonterapi ile birleştiren Merih
                  Tombul Fulya’da hayata geçirdiği muayenehanesinde hizmet
                  veriyor.
                </p>
                <b className="font-bold mb-5 block mt-10 text-sm">
                  Eğitimler/Sertifikalar
                </b>
                <p className="text-sm">
                  <ul>
                    <li>Akupunktur Sertifikası/ Medipol Üniversitesi 2017</li>
                    <li>Mezoterapi Sertifikası/ Medipol Üniversitesi 2017</li>
                    <li>Ozon Uygulama Sertifikası 2018</li>
                  </ul>
                </p>
                <b className="font-bold mb-5 block mt-10 text-sm">
                  Uygulamalar
                </b>
                <p className="text-sm mb-20">
                  <ul>
                    <li>Mezoterapi</li>
                    <li>Medikal saç tedavileri</li>
                    <li>Ozon Terapi</li>
                    <li>Prp</li>
                    <li>Vitamin ve Antioksidan tedavisi</li>
                  </ul>
                </p>
              </div>
            </p>
          </>
        )}
        {language == 'en' && (
          <>
            <p className="my-2.5">
              <img className="mb-10" src={sbb} width={500} />

              <b className="font-bold mb-5 block">
                Dr.Serdar Bora Bayraktaroğlu
              </b>
              <p className="">
                Serdar Bora Bayraktaroğlu graduated from Cerrahpaşa Faculty of
                Medicine in 2005 Graduated from the English Medicine Program.
                Plastic Surgery Şişli Etfal Hospital between 2005-2011 He
                completed his training at the Plastic Reconstructive and
                Aesthetic Surgery Clinic. He completed his 'Microsurgery and
                Hand Surgery' training in the same clinic. Dr. Bayraktaroğlu, in
                January 2009, in America Six months at The Facial Paralysis
                Institute in California-LA She successfully completed her
                'Facial Aesthetics' internship. Translated with DeepL.com (free
                version)
              </p>
              <p className="mt-5">
                Dr. Bayraktaroğlu completed his thesis in the field of
                microvascular surgery. Bayraktaroğlu has been practicing Plastic
                Reconstructive and Aesthetic She works as a Surgery Specialist.
                She started her academic career in stem cell Dr. Bayraktaroğlu,
                who enriched his doctorate, is also interested in He is also an
                undergraduate student in philosophy. Translated with DeepL.com
                (free version)
              </p>
              <p className="mt-5">
                Turkish Society of Plastic and Reconstructive Surgery and ISAPS
                ( international society of aesthetic plastic surgeons
                association) Dr. Bayraktaroğlu is a member of numerous national
                and international makes presentations on aesthetic plastic
                surgery in the organization; antiaging and medical aesthetics on
                international platforms closely follows developments around the
                world. Translated with DeepL.com (free version)
              </p>
              <p className="mt-5">
                In addition to his professional work in the medical field and
                shared his years of research in his book "An Unfiltered The
                World is Possible"; 'A Plastic Surgeon's Outlier It was
                published by Doğan Kitap on January 2024 with the note 'Karalama
                Defteri'. In which he discusses striking issues related to
                aesthetic plastic surgery and the second book is in the process
                of writing.
              </p>
            </p>
            <p className="my-2.5 mt-20 grid lg:grid-cols-12 gap-8 mb-8">
              <div className="lg:col-span-6">
                <img src={merihTombul} width={400} />
              </div>

              <div className="lg:col-span-6">
                <b className="font-bold mb-5 block">Dr. Merih Tombul</b>
                <p className="">
                  Graduated in 2008 from Trakya University Faculty of Medicine
                  After graduating from Şişli Etfal Education and Training
                  Center in Şişli Etfal between 2009-2013 Research and
                  specialization at Florence Nightingale Hospital after his
                  duty, he completed his Anesthesiology and Intensive care
                  experiences. Merih Tombul combining acupuncture, mesotherapy,
                  ozonotherapy He serves at his practice in Fulya.
                </p>
                <b className="font-bold mb-5 block mt-10 text-sm">
                  Trainings/Certificates
                </b>
                <p className="text-sm">
                  <ul>
                    <li>Akupunktur Sertifikası/ Medipol Üniversitesi 2017</li>
                    <li>Mezoterapi Sertifikası/ Medipol Üniversitesi 2017</li>
                    <li>Ozon Uygulama Sertifikası 2018</li>
                  </ul>
                </p>
                <b className="font-bold mb-5 block mt-10 text-sm">
                  Applications
                </b>
                <p className="text-sm mb-20">
                  <ul>
                    <li>Mesotherapy</li>
                    <li>Medical hair treatments</li>
                    <li>Ozone Therapy</li>
                    <li>Prp</li>
                    <li>Vitamin and Antioxidant therapy</li>
                  </ul>
                </p>
              </div>
            </p>
          </>
        )}
        <div className="mt-5">
          <Share title={t('labels.metaTitle', SCOPE_OPTIONS)} />
        </div>
      </div>
    </Layout>
  );
};

export default Tag;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
